<template>

    <div id="app" class="wrapper">
<!--        <router-view v-wechat-title="$route.meta.title" :key="$route.fullPath"></router-view>-->
        <router-view v-wechat-title="$route.meta.title"></router-view>
        <loading-comp v-if="$store.state.isLoading"></loading-comp>
        <loading-inplay-comp v-if="$store.state.iild"></loading-inplay-comp>
        <login-comp v-if="$store.state.isShowLoginPanel"></login-comp>
        <register-comp v-if="$store.state.isShowRegisterPanel"></register-comp>
        <not-memeber-customer-comp v-if="$store.state.isShowNotMemberCustomerePanel"></not-memeber-customer-comp>

    </div>


</template>

<script>
    import TopbarComp from "./components/TopbarComp";
    import FootComp from "./components/FootComp";
    import LoadingComp from "./components/LoadingComp";
    import {RECEIVE_HIDE_LOADING, RECEIVE_HIDE_LOADING_INPLAY} from "./store/mutation-types";
    import LoadingInplayComp from "./components/LoadingInplayBetComp";
    import LoginComp from "./components/LoginComp";
    import RegisterComp from "./components/RegisterComp";
    import NotMemeberCustomerComp from "./components/NotMemeberCustomerComp";

    export default {
        components: {
            NotMemeberCustomerComp,
            RegisterComp, LoginComp, LoadingInplayComp, LoadingComp, FootComp, TopbarComp},
        data() {
            return {}
        },
        created() {
            if (sessionStorage.getItem('state')) {
                this.$store.replaceState(Object.assign({}, this.$store.state, JSON.parse(sessionStorage.getItem('state'))))
            }
            window.addEventListener('pagehide', (ev) => {
                this.$store.commit(RECEIVE_HIDE_LOADING_INPLAY)
                this.$store.commit(RECEIVE_HIDE_LOADING)
                this.$store.state.sportsCartItems = []
                //window.sessionStorage.clear();
                //sessionStorage.setItem('state', '')
                sessionStorage.setItem('state', JSON.stringify(this.$store.state))
                return false
            })
        },
    }
</script>
